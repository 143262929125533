import React from 'react';
import { useSelector } from 'react-redux';

const Calendar = () => {
  // Acessa todos os subrequests do Redux
  const querystringResults = useSelector(
    (state) => state.querystringsearch.subrequests,
  );

  // Extrai e filtra apenas os itens que são eventos
  const eventItems = Object.values(querystringResults)
    .flatMap((subrequest) => subrequest.items || [])
    .filter((item) => item.type_title === 'Evento'); // Filtra por tipo de evento

  return (
    <div>
      <h1>Eventos Listados</h1>
      {eventItems.length > 0 ? (
        <ul>
          {eventItems.map((item, index) => (
            <li key={index}>{item.title}</li>
          ))}
        </ul>
      ) : (
        <p>Nenhum evento encontrado.</p>
      )}
    </div>
  );
};

export default Calendar;
