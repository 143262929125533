import { useDispatch } from 'react-redux';
import { setSidebarTab, setMetadataFocus } from '@plone/volto/actions';
import { Container } from '@plone/components';
import Calendar from '../Calendar/Calendar';

export const LeadImageSlot = () => {
  const dispatch = useDispatch();

  return (
    <Container
      layout
      className="lead-image-slot"
      onClick={() => {
        dispatch(setSidebarTab(0));
        dispatch(setMetadataFocus('default', 'preview_image_link'));
      }}
    >
      <Calendar />
      <h1>Olá</h1>
    </Container>
  );
};
