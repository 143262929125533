// SemanticUI-free pre-@plone/components
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { Container } from '@plone/components';
import Sitemap from '@plonegovbr/portalbrasil-intranet/components/Footer/Sitemap';
import Logo from '@kitconcept/volto-light-theme/components/Logo/Logo';
import config from '@plone/volto/registry';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ pathname }) => {
  const { settings } = config;
  const { display_sitemap } = settings.intranet?.footer;

  // Lógica para adicionar ou remover classes com base na URL
  useEffect(() => {
    const contentArea = document.querySelector(
      '.ui.basic.segment.content-area',
    );
    const anotherElement = document.querySelector('.another-class'); // Exemplo de outro elemento

    if (pathname === '/login') {
      if (contentArea) {
        contentArea.classList.add('custom-login-style');
      }
      if (anotherElement) {
        anotherElement.classList.add('custom-login-other-style');
      }
    } else {
      if (contentArea) {
        contentArea.classList.remove('custom-login-style');
      }
      if (anotherElement) {
        anotherElement.classList.remove('custom-login-other-style');
      }
    }
  }, [pathname]);
  return (
    <div>
      <footer id="footer">
        <Container className="footer">
          <Container className="logo">
            <Logo />
          </Container>
          {display_sitemap && (
            <Container narrow>
              <Sitemap pathname={pathname} />
            </Container>
          )}
          <Grid columns={2} stackable className={'about'}>
            <Grid.Column width={4}>
              <Container className="address">
                <span className="label">Endereço</span>
                <br />
                <span>Av. André Araújo, nº 200, Aleixo</span>
                <br />
                <span>Manaus - AM - 69060-000</span>
              </Container>
            </Grid.Column>
            <Grid.Column width={4}>
              <Container className="address">
                <span className="label">TRE-AM:</span>
                <br />
                <span>(92) 3632-4400</span>
                <br />
                <span className="label">Ouvidoria Eleitoral:</span>
                <br />
                <span>(92) 3632-4499</span>
                <br />
                <span className="label">Ouvidoria da mulher:</span>
                <br />
                <span>(92) 3632-4454</span>
              </Container>
            </Grid.Column>
            <Grid.Column width={4}>
              <Container className="working-hours">
                <span className="label">Horários</span>
                <br />
                <span>08 às 14 horas</span>
              </Container>
              {/* Calendário inserido abaixo dos horários */}
            </Grid.Column>
          </Grid>

          <a className="item powered-by" href="https://plone.org">
            <FormattedMessage
              id="Powered by Portal Brasil: Intranet, Plone & Python"
              defaultMessage="Powered by Portal Brasil: Intranet, Plone & Python"
            />
          </a>
          <br />
          <div className="footer-branding">
            Feito com{' '}
            <span role="img" aria-label="love" style={{ color: 'red' }}>
              ❤️
            </span>{' '}
            pelo PloneGov-BR
          </div>
        </Container>
      </footer>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
