import { LeadImageSlot } from './components/LeadImageSlot/LeadImageSlot';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['pt-br'],
    defaultLanguage: 'pt-br',
    intranet: {
      acessibilidade: {
        enable_contraste: false,
        enable_fonte: true,
        enable_link: false,
      },
      footer: {
        display_sitemap: true,
        display_toollogo: true,
      },
    },
  };

  // Registro do LeadImageSlot acima do conteúdo
  config.registerSlotComponent({
    slot: 'belowContent',
    name: 'LeadImageSlot',
    component: LeadImageSlot,
  });

  return config;
};

export default applyConfig;
